import { createMemo, For, Show } from 'solid-js';
import { MessageBar } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { gql } from '../graphql';
import { createFragment } from '../graphql/create-fragment';
import { dayToDayJs } from '../modules/date-formatting';
import type { FacilityClosureFragmentFragment, FacilityNoteFragmentFragment, FragmentType } from '../graphql';

type Props = {
	notes: Array<FragmentType<typeof FacilityNoteFragment>> | undefined;
	closures: Array<FragmentType<typeof FacilityClosureFragment>> | undefined;
	date: Date | undefined;
	timezone: string | undefined;
};

export function ClosureMessage(props: Props) {
	const notes = createFragment(FacilityNoteFragment, props, 'notes');
	const closures = createFragment(FacilityClosureFragment, props, 'closures');

	const filteredNotes = createMemo(() => {
		if (!notes) return [];
		if (!props.date) return notes;
		return notes.filter((note) => {
			const { startDay, endDay } = note;
			return (
				dayToDayJs(startDay, props.timezone ?? 'PST').isSameOrBefore(props.date) &&
				dayToDayJs(endDay, props.timezone ?? 'PST').isSameOrAfter(props.date)
			);
		});
	});

	const filteredClosures = createMemo(() => {
		if (!closures) return [];
		if (!props.date) return closures;
		return closures.filter((closure) => {
			const { startDay, endDay } = closure;
			return (
				dayToDayJs(startDay, props.timezone ?? 'PST').isSameOrBefore(props.date) &&
				dayToDayJs(endDay, props.timezone ?? 'PST').isSameOrAfter(props.date)
			);
		});
	});

	return (
		<Show when={filteredClosures().length || filteredNotes().length}>
			<div class="flex flex-col gap-2">
				<For each={filteredNotes()}>
					{(note) => (
						<MessageBar icon="info" class="w-full" appearance="dark">
							<p>{formatNotes(note)}</p>
						</MessageBar>
					)}
				</For>
				<For each={filteredClosures()}>
					{(closure) => (
						<MessageBar icon="circle-warning" appearance="danger" class="w-full">
							<p>{formatNotes(closure)}</p>
						</MessageBar>
					)}
				</For>
			</div>
		</Show>
	);
}

export function formatNotes(closure: FacilityNoteFragmentFragment | FacilityClosureFragmentFragment) {
	const { startDay, endDay } = closure;
	return ('reason' in closure ? closure.reason : 'note' in closure ? closure.note : '')
		?.replace('{startDate}', dayjs(`${startDay.year}-${startDay.month}-${startDay.day}`).format('MM/DD/YYYY'))
		.replace('{endDate}', dayjs(`${endDay.year}-${endDay.month}-${endDay.day}`).format('MM/DD/YYYY'));
}

const FacilityNoteFragment = gql(`fragment FacilityNoteFragment on FacilityNote {
	note
	startDay { year, month, day }
	endDay { year, month, day }
}`);

const FacilityClosureFragment = gql(`fragment FacilityClosureFragment on FacilityClosure {
	reason
	startDay { year, month, day }
	endDay { year, month, day }
}`);
