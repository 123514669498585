import { Icon } from '@troon/icons';
import { Dialog, DialogContent, DialogTrigger, Heading } from '@troon/ui';
import { createSignal, Show } from 'solid-js';
import { useUser } from '../../providers/user';
import { AuthFlow } from '../../partials/auth/auth';
import { TeeTimeAlertForm } from '../../partials/tee-time-alerts';
import type { ComponentProps } from 'solid-js';

type Props = {
	facilities: ComponentProps<typeof TeeTimeAlertForm>['facilities'];
};

export function TeeTimeAlertCallout(props: Props) {
	const user = useUser();
	const [authOpen, setAuthOpen] = createSignal(false);
	const [teeTimeAlertOpen, setTeeTimeAlertOpen] = createSignal(false);

	return (
		<div class="group relative flex flex-col gap-4 overflow-hidden rounded bg-brand p-6 text-white">
			<div
				class="absolute inset-0 z-0 cursor-pointer"
				onClick={() => {
					if (user()) {
						setTeeTimeAlertOpen(true);
					} else {
						setAuthOpen(true);
					}
				}}
			/>
			<Icon
				name="bell-ring"
				class="absolute -right-16 -top-8 z-10 size-48 -rotate-12 opacity-20 transition-transform group-hover:rotate-12"
			/>
			<div class="flex aspect-square size-14 items-center justify-center rounded-full bg-brand-400">
				<Icon name="bell-ring" class="size-8" />
			</div>
			<Heading as="h2" size="h5">
				Tee Time Alerts
			</Heading>
			<p>Be the first to know when a tee time becomes available that matches your playing preferences.</p>

			<Show
				when={user()}
				fallback={
					<Dialog key="tee-time-alert-auth" open={authOpen()} onOpenChange={setAuthOpen}>
						<DialogTrigger appearance="primary" class="z-10 size-fit grow-0 bg-white text-brand hover:bg-white/60">
							Log in to get notified
						</DialogTrigger>
						<DialogContent width="lg" noPadding closeButton="text-white">
							<AuthFlow
								onComplete={() => {
									setAuthOpen(false);
									setTeeTimeAlertOpen(true);
								}}
							/>
						</DialogContent>
					</Dialog>
				}
			>
				<Dialog key="tee-time-alert" open={teeTimeAlertOpen()} onOpenChange={setTeeTimeAlertOpen}>
					<DialogTrigger appearance="primary" class="z-10 size-fit grow-0 bg-white text-brand hover:bg-white/60">
						Set<span class="sr-only"> a Tee Time</span> Alert
					</DialogTrigger>
					<DialogContent header="Create a Tee Time Alert" headerLevel="h3" height="fit">
						<TeeTimeAlertForm
							facilities={props.facilities}
							onCancel={() => setTeeTimeAlertOpen(false)}
							onSaved={() => setTeeTimeAlertOpen(false)}
						/>
					</DialogContent>
				</Dialog>
			</Show>
		</div>
	);
}
